<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col>
          <h3>Daftar Job</h3>
        </v-col>
        <v-col
          cols="auto"
          class="ms-auto d-flex"
        >
          <input-icon-toggle
            v-model="state.jobFilter.value.search"
            class="ms-2 me-3"
            :icon="icons.mdiMagnify"
            placeholder="Cari job"
            @close="state.jobFilter.value.search = ''"
            @input="searchJob"
          />
          <v-menu
            v-model="isArchiveOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Job Dihapus / Diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedJob
              :is-archive-open="isArchiveOpen"
              :jobs="jobList"
              @update="fetchJobs()"
            />
          </v-menu>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="26"
                v-bind="attrs"
                v-on="on"
                @click="fetchJobs()"
              >
                {{ icons.mdiRefresh }}
              </v-icon>
            </template>
            <span>Muat Ulang</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row
        class="mx-2 mb-2"
      >
        <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <v-select
            v-model="state.jobFilter.value.sort"
            placeholder="Sortir Berdasarkan"
            :items="sortOptions"
            item-text="label"
            return-object
            outlined
            dense
            hide-details
            @change="fetchJobs()"
          />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          md="3"
        >
          <v-badge
            bordered
            overlap
            class="w-100 cursor-pointer"
            :value="filterCount"
            @click.native="clearFilter()"
          >
            <template #badge>
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </template>
            <v-btn
              outlined
              block
              large
              :text="!filterCount"
              :color="filterCount ? 'primary' : null"
              @click.stop="showDialogFilter = true"
            >
              Filter {{ filterCount ? `(${filterCount})` : '' }}
            </v-btn>
          </v-badge>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="state.jobFilter.value.types = [types[activeTab].id]; fetchJobs()"
                >
                  <v-icon>{{ icons.mdiViewDashboardVariant }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Pipeline</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="$can('create','Job')"
                class="ms-auto ms-md-4"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$refs.jobForm.show()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Buat Job</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-tabs-items v-model="viewTab">
        <v-tab-item>
          <v-tabs
            v-model="activeTab"
            class="px-0 px-md-4"
          >
            <v-tab
              v-for="(type, index) in types"
              :key="type.id"
              class="text-capitalize"
              @click="state.jobFilter.value.types = [type.id]; $refs.jobKanbanView.fetchJobStatus(type.id); fetchJobs(); fetchJobPriority()"
            >
              <div class="d-flex">
                <span class="mr-2">{{ type.name }}</span>
                <v-icon
                  v-if="activeTab === index &&( $can('update', 'JobStatus') || $can('del', 'JobStatus'))"
                  size="18"
                  @click.stop="$refs.typeForm.update(type)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </div>
            </v-tab>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  small
                  outlined
                  class="align-self-center mx-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="$refs.typeForm.add()"
                >
                  <v-icon
                    class="light--text"
                    size="20"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Buat Job Type Baru</span>
            </v-tooltip>
          </v-tabs>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            v-model="selectedRows"
            :headers="headers"
            :items="jobList"
            :items-per-page="20"
            item-key="id"
            show-select
            disable-sort
            :options.sync="state.jobFilter.value.pagination"
            :footer-props="{
              'items-per-page-options': [20, 40, 60],
              'items-per-page-text': 'Jumlah data',
            }"
            :server-items-length="jobCount"
            mobile-breakpoint="0"
            :loading="loading"
            @pagination="fetchJobs()"
          >
            <template v-slot:[`item.name`]="props">
              <router-link :to="{ name: 'job-detail', params: { id: props.item.id } }">
                <span class="primary--text font-medium d-block">{{ props.item.name }}</span>
              </router-link>
              <span class="text-caption text--disabled mb-1">{{ props.item.description }}</span>
            </template>
            <template v-slot:[`item.status`]="props">
              <div class="d-flex flex-wrap">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-1"
                      :color="props.item.priority.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ props.item.priority.name }}
                    </v-chip>
                  </template>
                  <span>Prioritas</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-1"
                      color="warning"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ props.item.job_type.name }}
                    </v-chip>
                  </template>
                  <span>Pipeline</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-1"
                      :color="props.item.status.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ props.item.status.name }}
                    </v-chip>
                  </template>
                  <span>Status</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.expected_close_date`]="props">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-subtitle-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ props.item.expected_close_date ? dateFormat(props.item.expected_close_date, 3) : '-' }}
                  </span>
                </template>
                <div class="d-flex flex-column">
                  <span>Dibuat: {{ dateFormat(props.item.created_at, 3) }}</span>
                  <span>Diperbarui: {{ dateFormat(props.item.updated_at, 3) }}</span>
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.created_by`]="props">
              <router-link :to="{ name: 'user-detail', params: { id: props.item.created_by.id } }">
                <span class="text-subtitle-2">{{ props.item.created_by.first_name }} {{ props.item.created_by.last_name }}</span>
              </router-link>
            </template>
            <template v-slot:[`item.customer`]="props">
              <router-link :to="{ name: 'customer-detail', params: { id: props.item.customer.id } }">
                <span class="text-subtitle-2">{{ props.item.customer.name }}</span>
              </router-link>
            </template>
            <template v-slot:[`item.assignedTo`]="props">
              <div class="d-flex align-center">
                <limit-elements
                  v-if="props.item.assignedTo.length"
                  :elements="props.item.assignedTo"
                  :limit="3"
                  class="v-avatar-group mr-auto"
                  :class="rootThemeClasses"
                >
                  <template #default="el">
                    <v-tooltip
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          size="36"
                          class="primary"
                          v-on="on"
                        >
                          <v-img
                            v-if="el.data.user.photo"
                            :src="el.data.user.photo"
                          />
                          <span
                            v-else
                            class="white--text"
                          >
                            {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                          </span>
                        </v-avatar>
                      </template>
                      <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                    </v-tooltip>
                  </template>
                  <template #others="el">
                    <v-tooltip
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          size="36"
                          class="primary mb-1"
                          v-on="on"
                        >
                          <v-img
                            v-if="el.data.user.photo"
                            :src="el.data.user.photo"
                          />
                          <span
                            v-else
                            class="white--text"
                          >
                            {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                          </span>
                        </v-avatar>
                      </template>
                      <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                    </v-tooltip>
                  </template>
                  <template #others-activator="el">
                    <v-tooltip
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          size="36"
                          color="white"
                          class="primary--text text-caption"
                          v-on="on"
                        >
                          <span>+{{ el.data.limit }}</span>
                        </v-avatar>
                      </template>
                      <span>Lihat User Lain</span>
                    </v-tooltip>
                  </template>
                </limit-elements>
                <v-menu
                  :close-on-content-click="false"
                  left
                >
                  <template v-slot:activator="menu">
                    <v-tooltip
                      top
                    >
                      <template v-slot:activator="tooltip">
                        <v-btn
                          v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                          text
                          outlined
                          small
                          rounded
                          color="primary"
                          v-on="{ ...menu.on, ...tooltip.on }"
                          @click.stop
                        >
                          <v-icon
                            size="18"
                          >
                            {{ icons.mdiAccountPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Tugaskan ke Pengguna</span>
                    </v-tooltip>
                  </template>

                  <assign-object
                    :job_id="props.item.id"
                    :data="props.item.assignedTo"
                    @success="$emit('refetch')"
                  />
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.action`]="props">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    @click="$refs.taskForm.show(props.item.customer, props.item)"
                  >
                    Buat Task
                  </v-list-item>
                  <v-divider class="my-1" />
                  <router-link :to="{ name: 'job-detail', params: { id: props.item.id } }">
                    <v-list-item>Lihat Detail</v-list-item>
                  </router-link>
                  <v-list-item
                    @click="$refs.jobForm.update(props.item.id)"
                  >
                    Ubah Data
                  </v-list-item>
                  <v-list-item
                    v-if="$can('del', 'Job')"
                    class="error--text"
                    @click="deleteJob(props.item.id)"
                  >
                    Hapus Job
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item class="mx-4">
          <v-data-iterator
            :items="jobList"
            :items-per-page="20"
            item-key="id"
            disable-sort
            :options.sync="state.jobFilter.value.pagination"
            :footer-props="{
              'items-per-page-options': [20, 40, 60],
              'items-per-page-text': 'Jumlah data',
            }"
            :server-items-length="jobCount"
            :loading="loading"
            @pagination="fetchJobs()"
          >
            <template v-slot:default="props">
              <v-row class="match-height">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                >
                  <job-card
                    :data="item"
                    @showtask="$refs.taskForm.show(item.customer, item)"
                    @delete="deleteJob($event)"
                    @update="$refs.jobForm.update($event)"
                    @refetch="fetchJobs()"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- <template
              slot="footer.page-text"
              slot-scope="item"
            >
              Melihat {{ item.pageStart }} - {{ item.pageStop }} dari total {{ item.itemsLength }} data
            </template> -->
          </v-data-iterator>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div style="height: 4px">
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
      />
    </div>
    <job-kanban-view
      v-if="viewTab === 0"
      ref="jobKanbanView"
      :types="types[activeTab]"
      :jobs.sync="jobList"
      :options.sync="state.jobFilter.value.pagination"
      class="mt-4"
      :items-per-page="20"
      :server-items-length.sync="jobCount"
      @showtask="$refs.taskForm.show($event.customer, $event.job)"
      @delete="deleteJob($event)"
      @update="$refs.jobForm.update($event)"
      @pagination="state.jobFilter.value.pagination = $event; fetchJobs()"
      @refetch="fetchJobs()"
    />

    <job-type-form
      ref="typeForm"
      @success="fetchJobTypes()"
    />

    <v-dialog
      v-model="showDialogFilter"
      max-width="800px"
      persistent
    >
      <v-card
        class="pa-4"
      >
        <div class="d-flex justify-space-between mb-4">
          <span class="text-h6 font-medium primary--text">Filter Job</span>
          <v-icon
            @click="showDialogFilter = false; fetchJobs()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <p class="mb-2 caption">
              Tanggal Ditutup
            </p>
            <date-range-picker
              :value="closeDate"
              @select="selectCloseDate"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <p class="mb-2 caption">
              Tanggal Diupdate
            </p>
            <date-range-picker
              :value="updateDate"
              @select="selectUpdateDate"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="state.jobFilter.value.search"
              label="Cari job"
              placeholder="Cari job berdasarkan nama"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="state.jobFilter.value.priority"
              :items="priorityOptions"
              label="Prioritas"
              outlined
              multiple
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              hide-details="auto"
            >
              <template #selection="{ item }">
                <v-chip
                  :color="item.color"
                  small
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <user-auto-suggest
              v-model="state.jobFilter.value.created_by"
              label="Dibuat Oleh"
              multiple
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <user-auto-suggest
              v-model="state.jobFilter.value.assigned_to"
              label="Ditugaskan Ke"
              multiple
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="state.jobFilter.value.types"
              :items="types"
              label="Job Type"
              outlined
              multiple
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              hide-details="auto"
              :disabled="viewTab === 0"
              @change="fetchJobStatus"
            >
              <template #selection="{ item }">
                <v-chip
                  small
                  :disabled="viewTab === 0"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <customer-auto-suggest
              v-model="state.jobFilter.value.customer"
              multiple
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="state.jobFilter.value.status"
              :items="status"
              label="Status Job"
              outlined
              multiple
              item-value="id"
              dense
              chips
              small-chips
              hide-details="auto"
              :disabled="status.length === 0"
            >
              <template #item="{item, attrs, on}">
                <v-list-item
                  #default="{ active }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.job_type.name }} - {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #selection="{ item }">
                <v-chip
                  small
                  :disabled="!status.length"
                >
                  {{ item.job_type.name }} - {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <div class="d-flex flex-column flex-md-row mt-4">
          <v-btn
            class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
            outlined
            @click="clearFilter()"
          >
            Reset Filter
          </v-btn>
          <v-btn
            color="primary"
            @click="showDialogFilter = false; fetchJobs()"
          >
            Filter Job
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <job-form
      ref="jobForm"
      @success="fetchJobs()"
    />

    <task-form ref="taskForm" />
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import {
  mdiMagnify, mdiPlus, mdiClose, mdiTable, mdiCardText, mdiRefresh, mdiArchive,
  mdiViewDashboardVariant, mdiPencil, mdiDotsVertical, mdiAccountPlus,
} from '@mdi/js'
import { useStorage, useDebounceFn } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { ellipsis, avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import { apolloClient } from '@/vue-apollo'
import {
  jobs, jobTypes,
  jobPriority, jobStatus,
} from '@/graphql/queries'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import JobTypeForm from './components/JobTypeForm.vue'
import JobCard from './components/JobCard.vue'
import errorHandling from '@/utils/errorHandling'
import dateFormat from '@/utils/dateFormat'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import JobKanbanView from './components/JobKanbanView.vue'
import JobForm from './components/JobForm.vue'
import useJob from '@/composables/useJob'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import store from '@/store'
import AssignObject from '@/components/inputs/AssignObject.vue'
import ArchivedJob from './components/ArchivedJob.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import router from '@/router'
import jobUpdateSubscription from '@/graphql/subscription/jobUpdateSubscription'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    TaskForm,
    InputIconToggle,
    JobTypeForm,
    JobCard,
    JobKanbanView,
    JobForm,
    UserAutoSuggest,
    CustomerAutoSuggest,
    LimitElements,
    AssignObject,
    ArchivedJob,
    DateRangePicker,
  },
  setup() {
    const closeDate = ref({ startDate: null, endDate: null })
    const updateDate = ref({ startDate: null, endDate: null })
    const isArchiveOpen = ref(false)
    const activeTab = ref(0)
    const viewTab = useStorage('viewTabJobs', 0)
    const jobList = ref([])
    const jobCount = ref(0)
    const loading = ref(false)
    const selectedRows = ref([])
    const types = ref([])
    const status = ref([])
    const headers = ref([
      {
        text: 'Nama',
        value: 'name',
        width: '320px',
      },
      {
        text: 'Prioritas - Job Type - Status',
        value: 'status',
        class: 'w-vdt-280',
      },
      {
        text: 'Pelanggan',
        value: 'customer',
        class: 'w-vdt-200',
      },
      {
        text: 'Ekspektasi Waktu Ditutup',
        value: 'expected_close_date',
        class: 'w-vdt-200',
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by',
        class: 'w-vdt-200',
      },
      {
        text: 'Ditugaskan ke',
        value: 'assignedTo',
        width: '220px',
      },
      {
        text: '',
        value: 'action',
      },
    ])

    const jobKanbanView = ref(null)
    const { deleteJob: delJob } = useJob()
    const state = {
      ...useFieldJob(['jobFilter', 'activeTabKanban']),
    }

    const fetchJobStatus = data => {
      if (data.length > 0) {
        apolloClient.query({
          query: jobStatus,
          fetchPolicy: 'no-cache',
          variables: {
            job_type: data,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(result => {
          status.value = result.data.jobStatus
        }).catch(err => {
          errorHandling(err)
        })
      } else status.value = []; state.jobFilter.value.status = []
    }

    onMounted(() => {
      closeDate.value.startDate = state.jobFilter.value.expected_close_date ? state.jobFilter.value.expected_close_date.date_min : null
      closeDate.value.endDate = state.jobFilter.value.expected_close_date ? state.jobFilter.value.expected_close_date.date_max : null

      updateDate.value.startDate = state.jobFilter.value.updated_at ? state.jobFilter.value.updated_at.date_min : null
      updateDate.value.endDate = state.jobFilter.value.updated_at ? state.jobFilter.value.updated_at.date_max : null

      activeTab.value = state.activeTabKanban.value ?? 0

      fetchJobStatus(state.jobFilter.value.types)
    })

    const showDialogFilter = ref(false)
    const { rootThemeClasses } = useVuetify()

    const selectCloseDate = value => {
      state.jobFilter.value.expected_close_date = {
        date_min: value.startDate,
        date_max: value.endDate,
      }
    }

    const selectUpdateDate = value => {
      state.jobFilter.value.updated_at = {
        date_min: value.startDate,
        date_max: value.endDate,
      }
    }

    const priorityOptions = ref(null)

    const sortOptions = [
      {
        label: 'Nama Job A-Z',
        field: 'name',
        type: 'ASC',
      },
      {
        label: 'Nama Job Z-A',
        field: 'name',
        type: 'DESC',
      },
      {
        label: 'Tanggal Ditutup Terlama',
        field: 'expected_close_date',
        type: 'ASC',
      },
      {
        label: 'Tanggal Ditutup Terdekat',
        field: 'expected_close_date',
        type: 'DESC',
      },
      {
        label: 'Diupdate Terlama',
        field: 'updated_at',
        type: 'ASC',
      },
      {
        label: 'Diupdate Terbaru',
        field: 'updated_at',
        type: 'DESC',
      },
    ]

    watch(activeTab, value => {
      state.activeTabKanban.value = value
    })

    const fetchJobs = () => {
      const filter = state.jobFilter.value
      loading.value = true
      apolloClient.query({
        query: jobs,
        fetchPolicy: 'no-cache',
        variables: {
          pagination: {
            limit: filter.pagination.itemsPerPage,
            offset: filter.pagination.page === 1 ? 0 : (filter.pagination.page - 1) * filter.pagination.itemsPerPage ?? 0,
          },
          filter: {
            search: filter.search.length ? filter.search : null,
            sort: filter.sort.field,
            order: filter.sort.type,
            job_type: filter.types.length ? filter.types : null,
            status: filter.status.length ? filter.status : null,
            created_by: filter.created_by.length ? filter.created_by.map(value => value.id) : null,
            customer: filter.customer.length ? filter.customer.map(el => el.id) : null,
            priority: filter.priority.length ? filter.priority : null,
            assigned_to: filter.assigned_to.length ? filter.assigned_to.map(value => value.id) : null,
            expected_close_date: filter.expected_close_date ? { date_min: filter.expected_close_date.date_min, date_max: filter.expected_close_date.date_max } : null,
            updated_at: filter.updated_at ? { date_min: filter.updated_at.date_min, date_max: filter.updated_at.date_max } : null,
          },
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        jobList.value = result.data.jobs.jobs
        jobCount.value = result.data.jobs.count
        setTimeout(() => {
          loading.value = false

          // delay before render again
          if (jobKanbanView.value) jobKanbanView.value.renderList()
        }, 300)
      }).catch(err => {
        loading.value = false
        router.push('/error')
        errorHandling(err)
      })
    }

    const fetchJobPriority = () => {
      apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_type: state.jobFilter.value.types,
        },
      }).then(result => {
        priorityOptions.value = result.data.jobPriority
      })
    }

    const fetchJobTypes = () => {
      loading.value = true
      apolloClient.query({
        query: jobTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        types.value = result.data.jobTypes
        if (viewTab.value === 0 && result.data.jobTypes.length) {
          const activeTabId = activeTab.value >= result.data.jobTypes.length
            ? result.data.jobTypes.length - 1
            : activeTab.value

          jobKanbanView.value.fetchJobStatus(result.data.jobTypes[activeTabId].id)
          state.jobFilter.value.types = [result.data.jobTypes[activeTabId].id]
          fetchJobs()
          fetchJobPriority()
        }
        loading.value = false
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }
    fetchJobTypes()

    const clearFilter = () => {
      closeDate.value = { startDate: null, endDate: null }
      updateDate.value = { startDate: null, endDate: null }
      store.dispatch('job/resetFilter')
      fetchJobs()
    }

    const searchJob = useDebounceFn(() => {
      fetchJobs()
    }, 1000)

    const deleteJob = id => {
      delJob(id).then(() => {
        fetchJobs()
      })
    }

    const filterCount = computed(() => {
      const filter = state.jobFilter.value
      let count = 0

      if (filter.search) count += 1
      if (filter.created_by.length) count += 1
      if (filter.assigned_to.length) count += 1
      if (filter.status.length) count += 1
      if (filter.types.length) count += 1
      if (filter.customer.length) count += 1
      if (filter.priority.length) count += 1
      if (filter.updated_at) count += 1
      if (filter.expected_close_date) count += 1

      return count
    })

    const observer = apolloClient.subscribe({
      query: jobUpdateSubscription,
      fetchPolicy: 'no-cache',
    })

    observer.subscribe({
      next(sub) {
        const update = sub.data.jobUpdateSubscription
        console.log('receiveJob', update.priority)

        fetchJobs()

        /* const idx = jobList.value.findIndex(el => el.id === update.id)
        jobList.value.splice(idx, 1, update)
        console.log(jobList.value[idx]) */
      },
      error(error) {
        console.error(error)
        errorHandling(error)
      },
    })

    return {
      fetchJobStatus,
      fetchJobPriority,
      updateDate,
      closeDate,
      selectCloseDate,
      selectUpdateDate,
      isArchiveOpen,
      activeTab,
      viewTab,
      jobList,
      jobCount,
      loading,
      selectedRows,
      headers,
      fetchJobs,
      showDialogFilter,
      clearFilter,
      types,
      status,
      fetchJobTypes,
      jobKanbanView,
      ellipsis,
      deleteJob,
      sortOptions,
      priorityOptions,
      state,
      dateFormat,
      searchJob,
      filterCount,
      avatarText,
      rootThemeClasses,

      icons: {
        mdiMagnify,
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiViewDashboardVariant,
        mdiPencil,
        mdiDotsVertical,
        mdiRefresh,
        mdiArchive,
        mdiAccountPlus,
      },
    }
  },
}
</script>

<style></style>
